import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';
import { environment } from 'environments/environment';
import moment from 'moment'
import { EntityDefinition, GroupDefinition, ValidatorType } from 'app/models/entities.model';
import { SwitchDataLakeService } from './switch-data-lake.service';
import * as XLSX from 'xlsx';

export var CompanyService_DOMAINS_CONFIG = "PORTAL";
export var CompanyService_CONFIG_DOMAIN = "ENTITY_DEFINITION";

@Injectable()
export class EntityConfigurationService {
	private baseUrl = environment.services.apiBasePath + "/" + CompanyService_DOMAINS_CONFIG + "/"+CompanyService_CONFIG_DOMAIN;
	
	constructor(private _httpService: HttpService, private _dataLake: SwitchDataLakeService) {
	}
	public getAll = (fieldsName?: string[]): Observable<EntityDefinition[]> => {
		let queryParams = ""
		if (fieldsName && fieldsName.length>0) {
			queryParams = "?";
			fieldsName.forEach(field => {
				queryParams += "fields="+field+ "&";
			})
		}

		return this._httpService.get(this.baseUrl + queryParams, "json");
	}

	// public getMyCompany = (profile: UserProfile): Observable<Company> => {
	// 	if (profile && profile.company)
	// 		return this.getCompany(profile.company.company);
	// 	return of(null);
	// }

	public getById = (id: any): Observable<EntityDefinition> => {
		let url = this.baseUrl + "/" + id;
		return this._httpService.get(url);
	}
	public getByField = (fieldId: string, value: any): Observable<EntityDefinition> => {
		let url = this.baseUrl + "/" + fieldId + "/" + value;
		return this._httpService.get(url);
	}
	public delete = (id: any): Observable<EntityDefinition> => {
		let url = this.baseUrl + "/" + id;
		return this._httpService.delete(url);
	}

	public saveOrUpdate = (entity: EntityDefinition): Observable<EntityDefinition> => {
		let url = this.baseUrl;
		entity.lastUpdateDate = moment().format("DD-MM-YYYY hh:mm");
		if (entity.id)
			return this._httpService.put(url + "/"+entity.id , entity,"json");
		return this._httpService.post(url,entity,"json")
	}

	public getSheetFromEntityDefinition = (entity: EntityDefinition): XLSX.WorkBook => {
		var workbook = XLSX.utils.book_new();
		let header: any = [];
		entity.baseInfo.forEach((info) => {
			let postFix = "";
			if (info.validators && info.validators.findIndex((validator) => {return validator.validatorType==ValidatorType.REQUIRED;})>=0 ) postFix = " *";
			header.push(info.fieldLabel + postFix);
		})
		entity.groups.forEach((group: GroupDefinition) => {
			if (!group.isArray) {
				group.fields.forEach((field) => {
					let postFix = "";
					if (field.validators && field.validators.findIndex((validator) => {return validator.validatorType==ValidatorType.REQUIRED;})>=0 ) postFix = " *";
					header.push(group.groupLabel + " - " + field.fieldLabel + postFix);
				})
			}
		});

		let data = [];
		data.push(header);
		let sheet = XLSX.utils.aoa_to_sheet(data, {});
		XLSX.utils.book_append_sheet(workbook, sheet, entity.name.substring(0,30));
		return workbook;
	}
}