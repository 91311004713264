import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalOptions } from 'app/common/models/models';
import { ModalService } from 'app/common/services/modal.service';
import { Company, EntityDefinition } from 'app/models/entities.model';
import { ClaimedNFT, Roles, UserProfile, WalletInfo } from 'app/models/profile.models';
import { ACR, ACRService, ARCPermission } from 'app/services/acr.service';
import { AuthService } from 'app/services/auth.service';
import { BlockChainService, EthAccount } from 'app/services/blockchain.service';
import { ProfileService } from 'app/services/profile.service';
// import { DOMAINS_CONFIG } from 'app/services/restaurant.service';
import { environment } from 'environments/environment';

import { saveAs } from 'file-saver';
import moment from 'moment';
import { forkJoin } from 'rxjs';
import { WalletImportModalForm } from '../wallet-import-modal/wallet-import-modal.page';
import { ToastService } from 'app/common/services/toasts.service';
import { EntityConfigurationService } from 'app/services/entity-configuration.service';
import { DataReloader } from 'app/pages/abstract-entity-management/components/list-object-preview/list-object-preview.component';
import { AddNewEntityModalEditor } from 'app/pages/abstract-entity-management/entity-navigation/add-entity-modal/add-new-entity.modal';
import { TranslateService } from '@ngx-translate/core';
import { ObjectReaderExcelFileMonal } from 'app/pages/abstract-entity-management/components/modal-excel-object-reader/modal-excel-object-reader.modal';
import { iconAdd, iconExcel } from 'app/common/utils/icons.utils';
import { FormFieldDescription } from 'app/common/models/forms';
import { catchError } from 'rxjs/operators';
import { ObjectReaderExcelHelperModal } from 'app/pages/abstract-entity-management/components/modal-excel-helper/modal-excel-helper.modal';
import * as XLSX from 'xlsx';

@Component({
	selector: 'user-profile-page',
	templateUrl: './user-profile.page.html'
})

export class UserProfilePage implements OnInit {
	pageLoaded = false;
	userProfile: UserProfile;
	formGroup: FormGroup;
	address: FormGroup;
	contacts: FormGroup;
	companyAddress: FormGroup;
	company: FormGroup;
	walletInfo: FormGroup;
	claimedNFT: FormArray;
	privateKey: string;
	userClaimedNFT: ClaimedNFT[] = [

	];

	iconAdd = iconAdd;
	iconExcel = iconExcel;

	availableNftToClaim: ClaimedNFT;

	possibileMediaTypes = ['image/png', 'image/jpeg', 'image/gif'];
	activeTab = "personalInfo";
	searchForm: FormGroup;
	dashboardsEnabled = [];
	
	userCompanies: Company[] = [];

	showGuideButton = environment.domainConfig.showGuideButton;
	showSupportButton = environment.domainConfig.showSupportButton;
	guideUrl = environment.domainConfig.guideButtonUrl;
	supportButtonUrl = environment.domainConfig.supportButtonUrl;

	entities: EntityDefinition[] = [];
	entitiesPermission: {
		entity:EntityDefinition,
		canUpdate: boolean
	}[] = [];
	// userObjects: any = {};
	userACR: ACR[] = [];
	constructor(
		private _entityConfigurationService: EntityConfigurationService,
		private _activatedRoute: ActivatedRoute,
		private _router: Router, 
		private _chainService: BlockChainService,
		private _userProfileService: ProfileService,
		private _fb: FormBuilder,
		private _authService: AuthService,
		private _modalService: ModalService,
		private _toastService: ToastService,
		private _translate: TranslateService,
		private _acrService: ACRService) {

			this.searchForm = this._fb.group({
				searchCompany: [""]
			})
	}

	public delegationUrl = environment.services.delegationUrl;

	public get delegationEnabled() {
		return false;
	}


	public get isUserAdmin() {
		return this.isAdmin || this._userProfileService.isInRole(Roles.USER_ADMIN);
	}
	public get isEntityConfigurator() {
		return this.isAdmin || this._userProfileService.isInRole(Roles.ENTITY_ADMIN);
	}
	public goToUserList = () => {
		this._activatedRoute.paramMap.subscribe(qp => {
			const queryParams = {};
			this._router.navigate(["/admin", "users-list"], { queryParams: queryParams });
		})
	}

	public goToUserDisabledList = () => {
		this._activatedRoute.paramMap.subscribe(qp => {
			const queryParams = {};
			this._router.navigate(["/admin", "disabled-users-list"], { queryParams: queryParams });
		})
	}

	ngOnInit() {
		this._userProfileService.loadUserProfile().subscribe((result) => {
			this.entitiesPermission = [];
			this.userProfile = result;
			this.initForm();
			this.formGroup.patchValue(this.userProfile);
			
			if (this.userProfile.claimedNFT)
				this.userClaimedNFT = this.userProfile.claimedNFT;
			else
				this.userClaimedNFT = [];
			

			const urlParams = new URLSearchParams(window.location.search);
			let nftClaim: ClaimedNFT = new ClaimedNFT();
			
			if (urlParams.has("cert_id")) nftClaim.cert_id = urlParams.get("cert_id");
			if (urlParams.has("cert_id_field_name")) nftClaim.certIdFieldName = urlParams.get("cert_id_field_name");
			// if (urlParams.has("domain")) nftClaim.domain = <DOMAINS_CONFIG>urlParams.get("domain");
			if (urlParams.has("productId")) nftClaim.productId = urlParams.get("productId");
			if (urlParams.has("entityId")) nftClaim.entityId = urlParams.get("entityId");
			if (urlParams.has("claimed_field_name")) nftClaim.claimedFieldName = urlParams.get("claimed_field_name");

			if (nftClaim.cert_id && nftClaim.domain) {

				if (this.userClaimedNFT.findIndex((nft: ClaimedNFT) => {return nft.cert_id == nftClaim.cert_id}) < 0)
					this.availableNftToClaim = nftClaim;
				
			
			}
			forkJoin({
				entities: this._entityConfigurationService.getAll(),
				userACRs: this._acrService.getACROfProfile(this.userProfile),

			}).subscribe((objs) => {
				this.userACR = objs.userACRs;
				if(this.isAdmin){
					this.entities = objs.entities;
					this.entities.forEach((entity) => {
						if (this.activeTab == "personalInfo") this.activeTab = 'entity-'+ entity.id;
						this.entitiesPermission.push({
							entity: entity, canUpdate: true
						})
					})
				}
				else {
					this.entities = objs.entities.filter((entityDef) => {
						let find;
						for (let i=0; i < this.userACR.length; i++) {
							let acr = this.userACR[i];
							if (entityDef.entityId == acr.entityId) {
								find = acr;
								i = this.userACR.length;
								this.entitiesPermission.push({
									entity: entityDef, canUpdate: (acr.permission==ARCPermission.EDITOR)?true:false
								})
							}
						}
						if (find) return true;
						return false;
					});
					if (this.entities && this.entities.length > 0) this.activeTab = 'entity-'+ this.entities[0].id;
				}
				
				
				// this.initDashboard(this.userProfile);
				this.pageLoaded = true;
			})
			

			
			
		})
	}

	public onEntitiesUpdates = (entitiesPermission) => {
		this.entitiesPermission = entitiesPermission;
		this.entities = entitiesPermission.map((element) => { return element.entity});
	}
	public getCompanyImage = (company: Company) => {
		if (company && company.logo) return environment.services.mediaContentsBasePath + "/" + company.logo;
		return "assets/img/icons/face-icon-0.jpg.png"
	}
	public goToCompany = (company: Company) => {
		if (company)
			this._activatedRoute.paramMap.subscribe(qp => {
				const queryParams = {};
				queryParams['returnUrl'] = "/user-profile"
				this._router.navigate(["/company", company.companyId], { queryParams: queryParams });
			})
		else
		this._activatedRoute.paramMap.subscribe(qp => {
			const queryParams = {};
			queryParams['returnUrl'] = "/user-profile"
			this._router.navigate(["/company"], { queryParams: queryParams });
		})
	}

	listObjectDataReloaders: any = {};
	setDataLoader = (reloader: DataReloader, entityId) => {
		this.listObjectDataReloaders[""+entityId] = reloader;
	}
	addNewEntityObject = (children: EntityDefinition) => {
		console.log("adding ", children);
		let modalOption: ModalOptions<any> = {
			size: 'xl',
			callback: (res) => {
				if (res) {
					// this.objectIstance = {};
					// Object.assign(this.objectIstance,res);
					console.log("result", res);
					if (this.listObjectDataReloaders[""+children.entityId]) {
						(<DataReloader>this.listObjectDataReloaders[""+children.entityId]).reloadData();
					}
				}
			}
		}
		
		let newHistory = [];
		
		let data = {
			profile: this.userProfile,
			currentEntityDef: children,
			objectIstance: null,
			parentIdChain: "root",
			rootAbstractEntity: children,
			historypath: newHistory

		}
		this._modalService.show(AddNewEntityModalEditor, data, modalOption);

	}
	public createSheet = (entityDefinition: EntityDefinition) => {
		let modalOption: ModalOptions<boolean> = {
			size: 'md',
			
			callback: (res) => {
				if (res) {
					var workbook = this._entityConfigurationService.getSheetFromEntityDefinition(entityDefinition);
					XLSX.writeFile(workbook, entityDefinition.name + ".xlsx");	
				}
			}
		}
		
		let data = {
			currentEntityDef: entityDefinition
		}
		this._modalService.show(ObjectReaderExcelHelperModal, data, modalOption);	
	}
	uploadFromExcel = (children: EntityDefinition) => {
		console.log("adding ", children);
		let modalOption: ModalOptions<any> = {
			size: 'xl',
			backdrop: 'static',
			keyboard: false,
			callback: (res) => {
				if (res) {
					if (this.listObjectDataReloaders[""+children.entityId]) {
						(<DataReloader>this.listObjectDataReloaders[""+children.entityId]).reloadData();
					}
				}
			}
		}
		
		let newHistory = [];
		
		let data = {
			profile: this.userProfile,
			currentEntityDef: children,
			objectIstance: null,
			parentIdChain: "root",
			rootAbstractEntity: children,
			historypath: newHistory

		}

		this._modalService.show(ObjectReaderExcelFileMonal, data, modalOption);		
	}

	public goToEntityHome = (event: {rootEntityDef, entityDef, currentObject, parentId, object?: any}) => {
		const queryParams = {};
		let navArray = [];
		if (event.object) {
			navArray = ["/entity-home", event.rootEntityDef.entityId, event.entityDef.entityId, event.parentId, event.object.id];
			
		}
		else {
			navArray = ["/entity-home", event.rootEntityDef.entityId, event.entityDef.entityId, event.parentId];

		}

		this._router.navigate(navArray, { queryParams: queryParams });
		
	}
	public goToEnabledDashboard = ($event: MouseEvent, navigationPath): void => {
		$event.preventDefault();
		$event.stopPropagation();

		this._activatedRoute.paramMap.subscribe(qp => {
			const queryParams = {};
			queryParams['returnUrl'] = "/user-profile"
			this._router.navigate(navigationPath, { queryParams: queryParams });
		})
	}

	claimNow() {
		
		this._chainService.claimNft(this.availableNftToClaim).subscribe(
			(result) => {
				if (! this.userProfile.claimedNFT) this.userProfile.claimedNFT = [];
				this.userProfile.claimedNFT.push(this.availableNftToClaim);
				let fg: FormGroup = this._fb.group({
					cert_id: [null],
					domain: [null],
					claimedFieldName: [null],
					certIdFieldName: []
				})
				fg.patchValue(this.availableNftToClaim);
				this.claimedNFT.push(fg);
				this.availableNftToClaim = null;
				this._userProfileService.saveOrUpdateProfile(this.formGroup.value).subscribe((r) => {
					this.userProfile = r;
					this.userClaimedNFT = this.userProfile.claimedNFT;
				})
				
			},
			(error) => {console.log(error);
			}
		);
	}
	public get profileImage() {
		if (this.userProfile && this.userProfile.avatarId)
			return environment.services.mediaContentsBasePath + "/" + this.userProfile.avatarId;
		return "assets/img/icons/face-icon-0.jpg.png"
	}

	public get firstName() {
		if (this.userProfile && this.userProfile.firstName)
			return this.userProfile.firstName;
	}
	public get lastName() {
		if (this.userProfile && this.userProfile.lastName)
			return this.userProfile.lastName;
	}

	public get isProfileComplete(): any {
		return " => " + (this.userProfile && this.userProfile.id != null && this.userProfile.id != undefined);
	}

	// localizedDescription: FormFieldDescription[] = [
	// 	{
	// 		defaultValue: null,
	// 		fieldName: "title",
	// 		label: "contents.editContentInputFieldTitleLabel",
	// 		fieldType: 'inputstring',
	// 		validators: [Validators.required]
	// 	}
	// ];

	private initForm = (): void => {

		this.address = this._fb.group({
			address: [null],
			cap: [null],
			city: [null],
			province: [null]
		});
		this.contacts = this._fb.group({
			mobilePhone: [null],
			fixedPhone: [null],
			pec: [null],
			alternativeEmail: [null]
		});


		this.companyAddress = this._fb.group({
			address: [null],
			cap: [null],
			city: [null],
			province: [null]
		});

		this.company = this._fb.group({
			company: [null],
			organization: [null],
			address: this.companyAddress
		});
		this.walletInfo = this._fb.group({
			creationDate: [null],
			pubKey: [null]
		});
		this.claimedNFT = this._fb.array([]);

		this.formGroup = this._fb.group({
			userId: [null],
			avatarId: [null],
			firstName: [null, Validators.required],
			lastName: [null, Validators.required],
			email: [null, Validators.required],
			isCompanyProfile: [true],
			fiscalCode: [null],

			id: [null],
			lastUpdateDate: [null],
			roles: [[]],
			walletInfo: this.walletInfo,
			claimedNFT: this.claimedNFT,
			company: this.company,
			contacts: this.contacts,
			address: this.address,
			enabledDashboards: [[]]
			// localizedData: this._fb.array([])
		});

		
		if (this.userProfile.claimedNFT ) {
			if (this.userProfile.claimedNFT.length > 0) {
				this.userProfile.claimedNFT.forEach((nft) => {
					let fg: FormGroup = this._fb.group({
						cert_id: [null],
						domain: [null],
						claimedFieldName: [null],
						certIdFieldName: []
					})
					fg.patchValue(nft);
					this.claimedNFT.push(fg);
				})
			}
			else {
				this.userProfile.claimedNFT = [];
			}
			
		}
		
	}

	setActiveTab(tab) {
		this.activeTab = tab;
	}


	onSaveAccountInfo = () => {

	}

	get baseInfoValid(): boolean {
		return this.formGroup.get("fiscalCode").valid;
	}
	
	saveUserProfile = () => {
		if (this.baseInfoValid && this.personalInfoValid && this.contactsValid) {
			this._userProfileService.saveOrUpdateProfile(this.formGroup.value).subscribe((result) => {
				this.userProfile = result;
			})
		}
	}

	savebaseInfo() {
		if (this.baseInfoValid) {
			this._userProfileService.saveOrUpdateProfile(this.formGroup.value).subscribe((result) => {
				this.userProfile = result;
			})
		}
	}
	get personalInfoValid(): boolean {
		return this.address.valid && this.formGroup.get("fiscalCode").valid;
	}
	savePersonalInfo() {
		if (this.personalInfoValid) {
			this._userProfileService.saveOrUpdateProfile(this.formGroup.value).subscribe((result) => {
				this.userProfile = result;
			})
		}
	}
	get contactsValid(): boolean {
		return this.address.valid;
	}
	saveContacts() {
		if (this.contactsValid) {
			this._userProfileService.saveOrUpdateProfile(this.formGroup.value).subscribe((result) => {
				this.userProfile = result;
			})
		}
	}

	get companyValid(): boolean {
		return this.address.valid;
	}
	saveCompany() {
		if (this.companyValid) {
			this._userProfileService.saveOrUpdateProfile(this.formGroup.value).subscribe((result) => {
				this.userProfile = result;
			})
		}
	}

	walletCreating= false;
	get walletAlreadyRequired(): boolean {
		return (this.userProfile && this.userProfile.walletInfo && this.userProfile.walletInfo.pubKey)?true:false;
	}
	createNewWallet = () => {
		this.walletCreating = true;
		let account: EthAccount = this._chainService.createAccount();
		this._chainService.createWallet({pubkey: account.pubblicKey})
			.subscribe((result => {
				let data: UserProfile = this.formGroup.value;
				data.walletInfo = new WalletInfo();
				data.walletInfo.creationDate = moment().utc().format();//valueOf();//format("YYYY-MM-DDThh:mm:ss");
				
				data.walletInfo.pubKey = account.pubblicKey;
				this._userProfileService.saveOrUpdateProfile(data).subscribe((result) => {
					this.userProfile = result;
					this.privateKey = account.privateKey;
					this.walletCreating = false;
				},
				(error) => {
					this.walletCreating = false;
				}
				)
			}),
			(error) => {
				this.walletCreating = false;
			}
			);
	}

	importWallet = () => {
		this.walletCreating = true;

		let modalOption: ModalOptions<EthAccount> = {
			backdrop : 'static',
      		keyboard : false,
			size: 'xl',
			callback: (account) => {
				if (account) {
					this._chainService.createWallet({pubkey: account.pubblicKey})
					.subscribe((result => {						
						let data: UserProfile = this.formGroup.value;
						data.walletInfo = new WalletInfo();
						data.walletInfo.creationDate = moment().utc().format();//valueOf();//format("YYYY-MM-DDThh:mm:ss");
						data.walletInfo.pubKey = account.pubblicKey;
						this._userProfileService.saveOrUpdateProfile(data).subscribe((result) => {
							this.userProfile = result;
							this.privateKey = account.privateKey;
							this.walletCreating = false;
							this._toastService.showSuccess(this._translate.instant("wallet.importSuccessMessage"));
						},(error) => {
							this.walletCreating = false;
						}
						)
					}),
					(error) => {
						if (error.indexOf("304 NOT MODIFIED") >= 0) {
							let data: UserProfile = this.formGroup.value;
							data.walletInfo = new WalletInfo();
							data.walletInfo.creationDate = moment().utc().format();//valueOf();//format("YYYY-MM-DDThh:mm:ss");
							data.walletInfo.pubKey = account.pubblicKey;
							this._userProfileService.saveOrUpdateProfile(data).subscribe((result) => {
								this.userProfile = result;
								this.privateKey = account.privateKey;
								this.walletCreating = false;
								this._toastService.showSuccess(this._translate.instant("wallet.importSuccessMessage"));
							},(error) => {
								this.walletCreating = false;
							})
						}
						this.walletCreating = false;
					}
					);
				}
				else {
					this.walletCreating = false;
				}
			}
		}
		let data = {}
		this._modalService.show(WalletImportModalForm, data, modalOption);
	}

	saveOnFile = () => {
		if (this.privateKey) {
			var blob = new Blob([this.privateKey], { type: "text/plain;charset=utf-8" });
			saveAs(blob, "wallet.txt", { type: "text/plain;charset=utf-8" });
		}
	}

	logout = () => {
		this._authService.logout();
	}

	public get isAdmin() {
		return this._userProfileService.isInRole(Roles.ADMIN);
	}


	public get logoImage(): string {
		return environment.domainConfig.companyLogo;
	}

	
}