// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
	authentication: {
		issuer: "https://iam-switchproject.posti.world/realms/switchproject",
		silentRefreshRedirectUri: "/assets/authentication/silent-refresh.html",
		clientId: "posti-bo",
		responseType: "code",
		scope: "openid profile offline_access roles phone address",
		redirectUri: null,
		postLogoutRedirectUri: null
	},
	services: {
		blockChainService: "https://api-gateway-switchproject.posti.world/blockchain-service",
		newBlockchainBasePath: "https://api-gateway-switchproject.posti.world/blockchain-service",

		mediaContentsBasePath:	"https://api-gateway-switchproject.posti.world/api-tmp/api/v1/bo/images",
		getMediaContentsBasePath:	"https://api-gateway-switchproject.posti.world/lp-images",
		restaurantService: "https://api-gateway-switchproject.posti.world/api-tmp/api/v1/bo/Restaurant",

		apiBasePath: "https://api-gateway-switchproject.posti.world/api-tmp/api/v1/bo",

		delegationUrl: "https://verifier-switchproject.posti.world/delegate",
		certDataBaseUrl: "https://verifier-switchproject.posti.world/json/{{companyId}}/{{certId}}.json",
		certVerifierUrl: "https://verifier-switchproject.posti.world/{companyId}/{certificateId}/verify",
		dataLakeUrl: null
	},
	certificationConfig: {

		
	},
	domainConfig: {
		defaultCertPagePatternURL: "https://verifier-switchproject.posti.world/{companyId}/{id}/verify",
		defaultProductLandingPagePatternURL: "https://switchproject.posti.world/{productId}",
		defaultEntityLandingPagePatternURL: "https://switchproject.posti.world/{productId}?id={id}",
		companyLogo: "assets/img/logos/Vertical_logo_switch.png",
		portalName: "Switch",
		portalTitle: "Switch Project",
		landingPageSiteLogo: "assets/img/logos/Vertical_logo_switch.png",
		appIconUrl: "assets/img/logos/Vertical_logo_switch.png",
		allowExternalProviders: false,
		emailContact: "info@blockco2",
		showGuideButton: false,
		showSupportButton: false,
		guideButtonUrl: "",
		supportButtonUrl: ""
	},
	certificatePageBaseUrl: "https://switchproject-certificate-lp.posti.world/landing",
	landingPageBaseUrl: "https://switchproject-certificate-lp.posti.world/landing-v2",
	startLoginAtStartup: true,
	appRoutes: [
		{
			path: '',
			redirectTo: "user-profile",
			pathMatch: 'full',
		},
		{
			path: 'landing',
		},
		{
			path: '**',
			redirectTo: 'pages/error'
		},
		{
			path: 'unautorized-page',
		}
	]
};
