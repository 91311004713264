import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { iconCancel, iconSave } from "app/common/utils/icons.utils";
import { CommonValidators } from "app/common/components/form/validators/common.validator";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectListitem } from 'app/common/models/models';

@Component({
    selector: 'agroecology-questionnaire',
    templateUrl: './agroecology-questionnaire.modal.html'
})
export class AgroecologyQuetionnaireModalPage implements OnInit {
	iconCancel = iconCancel;
	iconSave = iconSave;
	public entityForm: FormGroup = null;	
	data: {
		quetionnaire: any
	};
	theme_1_soil_fertility_fieldTypeItems: SelectListitem[] = [
		{id: 0, label: "All land is fertilized with synthetic fertilizers. No specific dosing is used for different plants or soil types. Little or no organic fertilizers are used. (OR farm has no access to fertilizers and practices no alternative fertility management)"},
		{id: 1, label: "Synthetic fertilizers are used regularly on most crops (>>50%) and some organic practices (e.g. manure or compost) are applied to some crops (<<50%)  and/or grasslands."},
		{id: 2, label: "Synthetic fertilizers are used on a few specific crop only. Organic practices are applied to the other crops and/or grasslands."},
		{id: 3, label: "Synthetic fertilizers are only used exceptionally. A variety of organic practices are the norm."},
		{id: 4, label: "No synthetic fertilizers are used, soil fertility is managed only through a variety of organic practices OR organic certification"}
	];
	theme_1_pests_and_diseases_fieldTypeItems: SelectListitem[] = [
		{id: 0, label: "Chemical pesticides and drugs are used regularly for pest and diseases management. No other management is used."},
		{id: 1, label: "Chemical pesticides and drugs are used for a specific crop/animal only. Some organic substances and organic practices are applied sporadically."},
		{id: 2, label: "Pests and diseases are managed through organic practices but chemical pesticides are used only in specific and very limited cases."},
		{id: 3, label: "No chemical pesticides and drugs are used. Organic substances are the norm."},
		{id: 4, label: "No chemical pesticides and drugs are used. Pests and diseases are managed through a variety of organic substances and prevention measures OR organic certification"}
		
	];
	theme_1_livestock_system_fieldTypeItems: SelectListitem[] = [
		{id: 0, label: "Intensive system. Animal well-being is not addressed beyond regulation limits"},
		{id: 1, label: "Intensive system with some measures to improve the animal conditions "},
		{id: 2, label: "Free range less than 50% of time spent outside cages."},
		{id: 3, label: "Free range more than 50% of time spent outside cages."},
		{id: 4, label: "Fully free-range farming OR organic certification."}
		
	];
	theme_2_polycropping_fieldTypeItems: SelectListitem[] = [
		{id: 0, label: "Fully monocropping"},
		{id: 1, label: "Monocropping with some natural landscape elements (e.g. trees, non-crop plants)"},
		{id: 2, label: "Prominently monocropping (>50%) but with some areas (<50%) for polycropping and presence of some natural landscape elements"},
		{id: 3, label: "Prominently polycropping (>50%) with presence of some natural landscape elements"},
		{id: 4, label: "Fully polycropping in an heterogeneous landscape with multiple nature-friendly landscape elements."}
		
	];
	theme_2_livestock_crop_fieldTypeItems: SelectListitem[] = [
		{id: 0, label: "No integration: animals, including fish, are fed with purchased feed and their manure is not used for soil fertility; or no animal in the agroecosystem. "},
		{id: 1, label: "Low integration: animals are mostly fed with purchased feed, their manure is used as fertilizer. "},
		{id: 2, label: "Medium integration: animals are mostly fed with feed produced on the farm and/or grazing, their manure is used as fertilizer. "},
		{id: 3, label: "High integration: animals are mostly fed with feed produced on the farm, crop residues and by-products and/or grazing, their manure is used as fertilizer. "},
		{id: 4, label: "Complete integration: animals are exclusively fed with feed produced on the farm, crop residues and by-products and/or grazing, all their manure is recycled as fertilizer."},
		
	];
	theme_3_recycle_fieldTypeItems: SelectListitem[] = [
		{id: 0, label: "Food loss, residues and waste are not recycled/valorized. "},
		{id: 1, label: "A small part of the Food loss, residues and waste is recycled/valorized (e.g. crop residues as animal feed, use of manure as fertilizer, production of compost from manure and household waste, green manure). "},
		{id: 2, label: "More than half of the Food loss, residues and waste is recycled/valorized. "},
		{id: 3, label: "Most of the Food loss, residues and waste are recycled/valorized. "},
		{id: 4, label: "All of the Food loss, residues and waste are recycled/valorized. "}
		
	];
	theme_4_sell_fieldTypeItems: SelectListitem[] = [
		{id: 0, label: "Products are exclusively distributed to large wholesalers and major distribution organizations, with limited direct consumer-producer connections."},
		{id: 1, label: "A small portion (<50%) is distributed to regional/local retailers and some engagement in direct local markets."},
		{id: 2, label: "A significant portion (>50%) is distributed to regional/local retailers."},
		{id: 3, label: "A substantial majority (>50%) is distributed regionally/locally through alternative/direct systems like CSA, food hubs, and farmers' markets, fostering direct consumer-producer relationships."},
		{id: 4, label: "All production is channeled through alternative/direct systems such as CSA, food hubs, and farmers' markets, prioritizing direct connections between producers and consumers OR is managed through stable contracts with trusted local distributors (they assure fair prices both for producers and consumers)."}
		
	];
	theme_5_breeds_fieldTypeItems: SelectListitem[] = [
		{id: 0, label: "The product originates from a non-local variety/breed and is not traditionally used in local cuisine. "},
		{id: 1, label: "While not from a local variety/breed, the product is a component of traditional local dishes."},
		{id: 2, label: "Both local and non-local varieties/breeds are produced. The latter are part of traditional cuisine (including minorities). "},
		{id: 3, label: "The majority of the food comes from local varieties/breeds and traditional knowledge and practices for food production are implemented when possible. "},
		{id: 4, label: "Local varieties/breeds are produced with recovery of traditional knowledge and production practices, when it applies. "}
		
	];
	theme_6_welfare_fieldTypeItems: SelectListitem[] = [
		{id: 0, label: "The farm adheres to standard legal requirements. No additional workers’ welfare measures are addressed."},
		{id: 1, label: "The farm adheres to standard legal requirements. Some additional workers’ welfare measures are addressed. "},
		{id: 2, label: "The farm explicitly prioritizes workers' welfare, offering several improved working conditions. "},
		{id: 3, label: "The farm sets a high benchmark by exceeding industry norms, providing exceptional working conditions and support."},
		{id: 4, label: "The farm pioneers innovative solutions tailored to workers' needs (e.g. specialized training programs, cultural integration initiatives, and community engagement projects, including engagement of vulnerable/marginalized groups) and implements strategies to ensure fair and affordable food prices through a sustainable regionalized food system (e.g. two-price structure)"}
		
	];
	public get title() {
		return  "Agroecology Questionnaire";
	}

	get formValid() {
		return this.entityForm && this.entityForm.valid;
	}

	ngOnInit() {
		this.initForm();	
	}
	
    constructor(
		private activeModal: NgbActiveModal,
		private _fb: FormBuilder
		) {
			
    }

	
	private initForm = () => {
		let entityFormDef: any = {
			livestock_farming: [false],
			workers: [false],
			theme_1_soil_fertility: [null, CommonValidators.required],
			theme_1_pests_and_diseases: [null, CommonValidators.required],
			theme_1_livestock_system: [null],

			theme_2_polycropping: [null, CommonValidators.required],
			theme_2_livestock_crop: [null],

			theme_3_recycle: [null, CommonValidators.required],
			theme_4_sell: [null, CommonValidators.required],
			theme_5_breeds: [null, CommonValidators.required],
			theme_6_welfare: [null]

		};
		this.entityForm = this._fb.group(entityFormDef);
		this.entityForm.get("workers").valueChanges.subscribe((workers) => {
			console.log("workers: " + workers);
			if (workers) {
				this.entityForm.get("theme_6_welfare").setValidators([CommonValidators.required]);
			}
			else {
				this.entityForm.get("theme_6_welfare").setValidators([]);
			}
			
		})
		this.entityForm.get("livestock_farming").valueChanges.subscribe((livestock_farming) => {
			console.log("livestock_farming: " + livestock_farming);	
			if (livestock_farming) {
				this.entityForm.get("theme_1_livestock_system").setValidators([CommonValidators.required]);
				this.entityForm.get("theme_2_livestock_crop").setValidators([CommonValidators.required]);
			}
			else {
				this.entityForm.get("theme_1_livestock_system").setValidators([]);
				this.entityForm.get("theme_2_livestock_crop").setValidators([]);
			}
		})


		this.entityForm.patchValue(this.data.quetionnaire);
	}


    public save() {
        if (this.entityForm.valid) {
			this.activeModal.close(this.entityForm.value);
        }
    }

	
	public get canSaveForm(): boolean {
		return this.entityForm && this.entityForm.valid;
	}

	public close = (): void => {
		this.activeModal.close(false);
    }
}


