import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { from, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { HttpService } from './http.service';
import jwtDecode from "jwt-decode";
import { environment } from 'environments/environment';
import moment from 'moment'
// import { DOMAINS_CONFIG } from './restaurant.service';
import { UserProfile } from '../models/profile.models';
import { Company } from 'app/models/entities.model';

export enum ARCPermission {
	VIEWER = "VIEWER",
	EDITOR = "EDITOR"
}

// access control rule
export class ACR {
	id: any;
	entityDomain: any;
	entityId: any;
	entityName: string;
	userId: string;
	permission?: ARCPermission;
}

var ACR_DOMAINS_CONFIG = "__ACR"
@Injectable()
export class ACRService {
	private baseUrl = environment.services.apiBasePath + "/" + ACR_DOMAINS_CONFIG;
	
	constructor(private _oauthService: OAuthService, protected _httpService: HttpService) {
	}
	
	public getAll = (fieldsName?: string[]): Observable<ACR[]> => {
		let queryParams = ""
		if (fieldsName && fieldsName.length>0) {
			queryParams = "?";
			fieldsName.forEach(field => {
				queryParams += "fields="+field+ "&";
			})
		}

		return this._httpService.get(this.baseUrl+ "/" + ACR_DOMAINS_CONFIG + queryParams, "json");
	}
	public getACR = (id: any): Observable<ACR> => {
		let url = this.baseUrl+ "/" + ACR_DOMAINS_CONFIG + "/" + id;
		return this._httpService.get(url);
	}
	public getACRByDomain = (domainId: any): Observable<ACR[]> => {
		let url = this.baseUrl+ "/" + ACR_DOMAINS_CONFIG + "?entityDomain=" +domainId;
		return this._httpService.get(url).pipe(
			catchError(r => {
				return of([])
			}),
			map((result: ACR[]) => {
				return result.filter((r) => {
					return r.entityDomain == domainId;
				})
			})
		);
	}
	public getACRByEntityId = (entityId: string): Observable<ACR[]> => {
		
		let url = this.baseUrl+ "/" + ACR_DOMAINS_CONFIG + "?entityId=" + entityId;
		return this._httpService.get(url).pipe(
			catchError(r => {
				return of([])
			}),
			map((result: ACR[]) => {
				return result.filter((r) => {
					return r.entityId == entityId;
				})
			})
		);
	}
	public getACROfProfile = (userProfile: UserProfile): Observable<ACR[]> => {
		let url = this.baseUrl+ "/" + ACR_DOMAINS_CONFIG + "?userId=" +userProfile.userId;
		return this._httpService.get(url).pipe(
			catchError(r => {
				return of([])
			}),
			map((result: ACR[]) => {
				return result.filter((r) => {
					return r.userId == userProfile.userId;
				})
			})
		);
	}

	public getACRByField = (fieldId: string, value: any): Observable<ACR[]> => {
		let url = this.baseUrl+ "/" + ACR_DOMAINS_CONFIG + "/" + fieldId + "/" + value;
		return this._httpService.get(url);
	}
	
	public deleteACR = (acr: ACR): Observable<ACR> => {
		let url =  this.baseUrl+ "/" + ACR_DOMAINS_CONFIG + "/" + acr.id;
		return this._httpService.delete(url);
	}
	public saveOrUpdateACR = (acr: ACR, toNotify: boolean = false): Observable<ACR> => {
		let url = this.baseUrl+ "/" + ACR_DOMAINS_CONFIG;
		if (acr.id)
			return this._httpService.put(url + "/"+acr.id + "?notify="+toNotify, acr, "json");
		return this._httpService.post(url + "?notify="+toNotify, acr,"json");
	}









	public getMyCompanyACR = (userProfile: UserProfile): Observable<ACR[]> => {
		let url = this.baseUrl + "/companies?userId=" +userProfile.userId;
		return this._httpService.get(url).pipe(
			catchError(r => {
				return of([])
			}),
			map((result: ACR[]) => {
				return result.filter((r) => {
					return r.userId == userProfile.userId;
				})
			})
		);
	}
	public getCompanyACR = (companyId): Observable<ACR[]> => {
		let url = this.baseUrl + "/companies/entityDomain/"+companyId+"?asList=true";
		return this._httpService.get(url);
	}

	public linkCompanyToUser = (userProfile: UserProfile, company: Company, role: "ADMIN"): Observable<ACR> => {
		let url = this.baseUrl+ "/companies";
		let acr = {
			entityId: company.id,
			entityDomain: company.companyId,
			userId: userProfile.userId,
			entityName: company.name,
			role: role
		}
		return this._httpService.post(url, acr,"json");
	}

	public deleteCompanyACR = (acr: ACR): Observable<ACR> => {
		let url =  this.baseUrl+ "/companies/" + acr.id;
		return this._httpService.delete(url);
	}
	public saveOrUpdateCompanyACR = (acr: ACR, toNotify: boolean = false): Observable<ACR> => {
		let url = this.baseUrl+ "/companies";
		if (acr.id)
			return this._httpService.put(url + "/"+acr.id + "?notify="+toNotify, acr, "json");
		return this._httpService.post(url + "?notify="+toNotify, acr,"json");
	}

}

