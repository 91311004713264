import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { iconCancel, iconSave } from "app/common/utils/icons.utils";

import { ActivatedRoute } from '@angular/router';
import { EntityDefinition, FieldDefinition, FieldType, ValidatorType} from 'app/models/entities.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableAction, DataTableColumn, ListTableManager } from 'app/common/components/table/types';
import { BaseListTablePage } from 'app/common/components/pages/base-list-table.page';
import { DataTableUtils } from 'app/common/utils/data-table.utils';
import * as XLSX from 'xlsx';
import { CommonValidators } from 'app/common/components/form/validators/common.validator';
import { ProfileService } from 'app/services/profile.service';
import { UserProfile } from 'app/models/profile.models';
import { BlockChainService } from 'app/services/blockchain.service';
import moment from 'moment';
import { StoredObjectService } from 'app/services/stored-object.service';
import { ToastService } from 'app/common/services/toasts.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'excel-object-reader',
    templateUrl: './modal-excel-object-reader.modal.html'
})
export class ObjectReaderExcelFileMonal extends BaseListTablePage<any> {
	dataLoaded;
	public tableColumns: DataTableColumn[] = [];
    public tableActions: DataTableAction[] = [];
	private userProfile: UserProfile;
	public walletForm: FormGroup = null;
	
	iconCancel = iconCancel;
	iconSave = iconSave;
	uploading: boolean = false;
	uploaded: boolean = false;
	data: {
		currentEntityDef: EntityDefinition

		profile: UserProfile,
			// currentEntityDef: EntityDefinition,
			// objectIstance: null,
			parentIdChain: any,
			rootAbstractEntity: EntityDefinition,
			historypath: any
	};

	dataToImport: any = [];
	loadedDataError = false;
	langCode = "it";

	constructor(
		_activatedRoute: ActivatedRoute,
		private activeModal: NgbActiveModal,
		private _fb: FormBuilder,
		private _blockChainService: BlockChainService,
		private _storedObjectService: StoredObjectService,
		private _translate: TranslateService
		) {
			super(_activatedRoute,false);
			this.langCode = "custom-file-label-"+this._translate.currentLang;
    }

	protected getDataTableManager = (): ListTableManager<any> => {
        return new ListTableManager(this.getEntities)
    }

	private getEntities = (): Observable<any[]> => {
		return of(this.dataToImport);
	}

	initialize = (): void => {	
		this.dataLoaded = false;
		this.userProfile = this.data.profile;
		if (this.userProfile && this.userProfile.walletInfo && this.userProfile.walletInfo.pubKey) {
			this.walletForm =this._fb.group({
				secret: [null, [CommonValidators.required, CommonValidators.walletPrivateKeyValidator(this.userProfile.walletInfo.pubKey, this._blockChainService)]]
			});
		}
		this.createColumms();
	}
	public close = (): void => {
		this.activeModal.close(true);
    }

	private addColumn = (field: FieldDefinition, columnId, columnName) => {
		let column: DataTableColumn;
		if (field.fieldType == FieldType.DATE) {
			column = DataTableUtils.createDateColumn(columnId,columnName, true);
		}
		else if (field.fieldType == FieldType.MULTIPLE_SELECT) {
			column = DataTableUtils.createArrayColumn(columnId,columnName, null, false);
		}
		else if (field.fieldType == FieldType.DECIMAL) {
			column = DataTableUtils.createNumberColumn(columnId,columnName, 2, true);
			
		}
		else if (field.fieldType == FieldType.INTEGER) {
			column = DataTableUtils.createIntColumn(columnId,columnName, true);
		}
		else {
			column = DataTableUtils.createStringColumn(columnId,columnName, true);
		}
		this.tableColumns.push(column);
	}

    private createColumms = (): void => {
		this.tableColumns = [];
		this.data.currentEntityDef.baseInfo.forEach((entity) => {
			this.addColumn(entity, entity.fieldLabel,entity.fieldLabel);
		})
		this.data.currentEntityDef.groups.forEach((group) => {
			group.fields.forEach((entity) => {
				this.addColumn(entity, group.groupLabel + " - " + entity.fieldLabel,entity.fieldLabel);
			})
			
		})
    }

	private getFieldValue = (fieldDef: FieldDefinition, value: any): any => {
		let requiredNotFound = false;
		let required = false;
		fieldDef.validators?.forEach((validator) => {
			if (validator.validatorType == ValidatorType.REQUIRED) {
				if (value == null || value == undefined) {
					this.loadedDataError = true;
					requiredNotFound = true;
				}
				required = true;
			}
			
		})
		if (requiredNotFound) return undefined;
		

		if (fieldDef.fieldType == FieldType.DATE) {
			if (Number.isInteger(value)) {
				// attenzione. excel gestisce le date come il numero di giorni a partire dal 01/01/1900
				// in Javascript, invece, la funzione Date considera il numero di millisecondi dal 01/01/1970.
				// quindi bisogna convertire il valore di excel in millisecondi e poi sottrarre i millisecondi intercorsi ta il 1970 ed il 1900.
				// la stranezza è che bisogna sottrarre ulteriori 3 giorni altrimenti le date non si trovano.
				let d = new Date(value*1000*60*60*24 - 2209161600000);
				return moment(d);
			}
			else {
				let result = moment(value);
				if (result.isValid()) return result;
				this.loadedDataError = true;
				return undefined;
			}
		}
		else if (fieldDef.fieldType == FieldType.MULTIPLE_SELECT) {
			let result = (""+value).split(",").map((element) => {
				return ""+element.trim();
			}).filter((v) => {
				return fieldDef.possibleValues.indexOf(v) >= 0;
			})
			if (result && result.length > 0) return result;
			this.loadedDataError = true;
			return undefined;
		}
		else if (fieldDef.fieldType == FieldType.SINGLE_SELECT) {
			let result =  (fieldDef.possibleValues.indexOf((""+value).trim()) >= 0)? (""+value).trim(): "";
			if (result && result.length > 0) return result;
			this.loadedDataError = true;
			return undefined;
		}
		else if (fieldDef.fieldType == FieldType.DECIMAL) {
			let result =   Number.parseFloat(value)
			if (!Number.isNaN(result)) return result;
			this.loadedDataError = true;
			return undefined;
		}
		else if (fieldDef.fieldType == FieldType.INTEGER) {
			let result = Number.parseInt(value)
			if (Number.isInteger(result)) return result;
			this.loadedDataError = true;
			return undefined;
		}
		else {
			let result = (""+value).trim();
			if (!required) return result;
			else if (required && result.length > 0) return result;
			this.loadedDataError = true;
			return undefined;
		}
	}
	private validateData = (data: any): any => {
		let result: any = new Object();
		this.data.currentEntityDef.baseInfo.forEach((field) => {
			let postFix = "";
			if (field.validators && field.validators.findIndex((validator) => {return validator.validatorType==ValidatorType.REQUIRED;})>=0 ) postFix = " *";

			result[""+ field.fieldLabel] = this.getFieldValue(field,data[""+ field.fieldLabel+postFix])
			
		})
		this.data.currentEntityDef.groups.forEach((group) => {
			group.fields.forEach((field) => {
				let postFix = "";
				if (field.validators && field.validators.findIndex((validator) => {return validator.validatorType==ValidatorType.REQUIRED;})>=0 ) postFix = " *";
				result[group.groupLabel + " - " +field.fieldLabel] = this.getFieldValue(field,data[group.groupLabel + " - " +field.fieldLabel+postFix])
			})
		})
		return result;
	}
	fileSelected: string = "excel.noFileSelectedMessage";

	public onFileSelected(fileList: FileList): void {
		this.dataLoaded = false;
		let file = fileList[0];
		if (file) {
			if (file.name.endsWith(".xls") || file.name.endsWith(".xlsx") ) {
				this.fileSelected = file.name;
				let fileReader: FileReader = new FileReader();
				fileReader.onload = (x) => {

					var data = x.target.result;
					
					
					var workbook = XLSX.read(data);
					// var workbook = XLSX.read(data, {type:'binary',cellText:false,cellDates:true});
					var jsa = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {
						blankrows: false,
						// raw: false,
						// dateNF: "yyyy-mm-dd"
						// dateNF: 'dd/MM/yyyy'
						raw: true
					});					
					this.dataToImport = [];
					this.loadedDataError = false;
					jsa.forEach((data) => {
						this.dataToImport.push(this.validateData(data))
					})

					// this.dataToImport = jsa;
					this.dataLoaded = true;
					this.dataTableManager.startReload();					
				}
				fileReader.readAsArrayBuffer(file);
			}
			else {
				this.fileSelected = "excel.noFileSelectedExtendMessage";
			}
		}
		else {
			this.fileSelected = "excel.noFileSelectedExtendMessage";
		}
	}

	title =  "excel.uploadFromExcelModalTitle";
	
	exportedDataCount = 0;
	dataCount = 20;
	get importLabel() {
		if (this.exportedDataCount < this.dataCount && this.uploading) return "excel.uploadRunning";
		return "excel.uploadCompleted";
	}
	get exportedDataCountPercent() {
		if (this.dataCount > 0) return (this.exportedDataCount/this.dataCount)*100;
		return 0;
	}

	importData = async (toCertify?) => {
		if (this.dataToImport && this.dataToImport.length > 0) {
			this.dataCount = this.dataToImport.length;
			this.uploading = true;
			let dataToSave: any[] = [];
			
			this.dataToImport.forEach((element) => {
				let newEntity: any = {};
				this.data.currentEntityDef.baseInfo.forEach((field) => {
					if (field.fieldType == FieldType.DATE) newEntity[field.fieldLabel] = moment(element[field.fieldLabel]).toISOString();
					else newEntity[field.fieldLabel] = element[field.fieldLabel];
				})
				this.data.currentEntityDef.groups.forEach((group, index) => {
					newEntity[group.groupLabel] = {};
					group.fields.forEach((field) => {
						if (field.fieldType == FieldType.DATE) newEntity[group.groupLabel][""+field.fieldLabel] = moment(element[group.groupLabel + " - " +field.fieldLabel]).toISOString();
						else newEntity[group.groupLabel][""+field.fieldLabel] = element[group.groupLabel + " - " +field.fieldLabel];
						
					})
				})
				newEntity.parentId = this.data.parentIdChain;
				newEntity.entitiDefId = this.data.currentEntityDef.entityId;
				newEntity.rootEntityDefId = this.data.rootAbstractEntity.entityId;

				dataToSave.push(newEntity);
			});			
			for (const row of dataToSave) {	
				let result = await this._storedObjectService.createAndWait(this.data.currentEntityDef,row);
				if (toCertify && this.walletForm && this.walletForm.valid) {
					await this._storedObjectService.certify(
							result,
							this.data.historypath,
							this.data.rootAbstractEntity,
							this.data.currentEntityDef,
							this.userProfile,
							this.walletForm.value.secret).then((r) => {})
				}
				this.exportedDataCount++;
			}
			this.uploading = false;
			this.uploaded = true;
		}
	}

	public get canSaveData() {
		// return true;
		return (this.dataToImport && this.dataToImport.length > 0 && !this.loadedDataError && this.exportedDataCount == 0);
	}
	public get canSaveAndCertify() {
		// return true;
		return (this.canSaveData && this.walletForm && this.walletForm.valid);
	}
}


